import { ChangeEvent, useState } from "react";

import {  Inputs } from "@state/types";
import { useStore } from "@state/store";
import { handleFileSize, replacePlaceholdersInValues } from "@util/helper";
import { TemplateFieldset } from "@util/types";

export default function useDashboard() {  
  const [fileName, setFileName] = useState<string | null>();
  const templateAvailable = useStore(state => state.templateAvailable);
  const templateFieldset = useStore(state => state.templateFieldset);
  const template = useStore(state => state.template);
  const setTemplate = useStore(state => state.setTemplate);

  const [inputs, setInputs] = useState<Inputs>(getInputs(templateFieldset));

  const handleUpdateFormData = (temp = template) => {
    const swappedText = replacePlaceholdersInValues(
      temp?.adCopy?.textElements,
      inputs,
    );
    const swappedImages = temp?.formData?.logo
      ? temp?.adCopy?.imageElements?.map((image) => {
          if (image.istemplateLogo && temp?.formData?.logo) {
            return {
              ...image,
              value: temp?.formData?.logo,
            };
          }
          return image;
        })
      : temp?.adCopy?.imageElements;

    const swapped = {
      textElements: swappedText,
      imageElements: swappedImages,
    };

  setTemplate({ ...(temp || {}), adCopy: { ...temp?.adCopy || {}, ...swapped}, formData: inputs });
};

  const handleSelectTemplate = (templateID: string) => {
    const foundTemplate = templateAvailable.templates.find(
      (template: any) => template.id === templateID,
    );
    if (foundTemplate) {
      if (hasFormData(inputs)) {
        handleUpdateFormData(foundTemplate);
        return;
      }
      setTemplate({ ...foundTemplate, formData: inputs });
    }
  };

  const onChangeInput = (key: string, value: string) => {
    const updated = { ...inputs, [key]: value } as Inputs;
    setInputs(updated);
  };

  const handleSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setFileName(file.name);

      handleFileSize(file, () => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.addEventListener("load", () => {
          onChangeInput("logo", reader?.result as string);
        });
      });
    }
  };

  return { handleSelectTemplate, handleSelectFile, onChangeInput, fileName, inputs, handleUpdateFormData };
}

const getInputs = (data: TemplateFieldset[] | null) => {
  const state: any = {};

  data?.forEach(field => {
    state[field?.name] = "";
  });

  return state;
};

const hasFormData = (inputs: Inputs) => {
  return Object.values(inputs).some(value => Boolean(value.length));
}