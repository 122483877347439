import React, { createContext, ReactNode, useEffect } from "react";
import { SelectedTemplate } from "@state/types";
import { Link } from "@global";
import SEO from "@shared/seo";
import { GlobalStyle } from "@styles/globalStyles";
import { appPaths, HEADER_HEIGHT, AppPath, assets, colors } from "@util/constants";
import { Container, TextButton, VerticalSeparator } from "@util/standard";
import { navigate } from "gatsby";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useStore } from "@state/store";
import { useTemplateHooks } from "@util/templateHooks";

interface Props {
  children: ReactNode;
  valueProps: DashboardProps;
}

export interface DashboardProps {
  userTemplate?: SelectedTemplate;
  setUserTemplate?: (template: Partial<SelectedTemplate>) => void;
}

interface LayoutProps {
  children: ReactNode;
  currentPath: AppPath;
  hideFooter?: boolean;
  onRightButtonClick?: (args?: any) => void;
  onBackClick?: (args?: any) => void;
  historyBack?: () => void;
}

const routes = [
  { path: appPaths.dashboard, title: "Projects", key: "dashboard" },
  { path: appPaths.myOrders, title: "My Orders", key: "myOrders" },
];

export const DashboardContext = createContext<DashboardProps | null>(null);

export const DashboardProvider = ({ children, valueProps }: Props) => {
  return <DashboardContext.Provider value={valueProps}>{children}</DashboardContext.Provider>;
};

export function DashboardLayout(props: LayoutProps) {
  const { children, currentPath, hideFooter, ...rest } = props;
  const templateAvailable = useStore(state => state.templateAvailable);
  const product = useStore(state => state.product);
  const { returned } = templateAvailable;
  const { fetchAll } = useTemplateHooks();

  useEffect(() => {
    if (!product && returned) return;
    fetchAll(product?.gridCode);
  }, [product, returned]);

  return (
    <div>
      <GlobalStyle defaultColor="navy" background={colors.dashGrey} />
      <SEO overwriteTitle={`Sales Tool - ${currentPath}`} slug={appPaths[currentPath]} />
      <Nav {...rest} currentPath={currentPath} />
      {children}
      <ToastContainer position="bottom-right" theme="colored" autoClose={3000} />
    </div>
  );
}

const Wrapper = ({ children }: { children: ReactNode }) => (
  <Container
    backgroundColor="navy"
    height={`${HEADER_HEIGHT}px`}
    justifyContent="space-between"
    alignItems="center"
    padding="0 0 0 30px"
  >
    {children}
  </Container>
);

const Nav = ({
  currentPath,
  onRightButtonClick,
  onBackClick,
  historyBack,
}: Partial<LayoutProps>) => {
  const handleClick = (key: string) => () => {
    const appPath = appPaths[key as AppPath];
    if (!appPath) return;
    navigate(appPath);
  };

  const MainPaths = () => {
    return (
      <Container alignItems="center">
        {routes.map((route, index) => {
          const { path, title, key } = route;
          return (
            <TextButton
              key={index}
              fontWeight={currentPath === path ? "bold" : "normal"}
              color="white"
              onClick={handleClick(key as AppPath)}
              margin="0 25px 0 0"
            >
              {title}
            </TextButton>
          );
        })}
      </Container>
    );
  };

  return (
    <Wrapper>
      <Container columnGap={30}>
        <img src={assets.logoWhite} />
        <VerticalSeparator width="0" color="white" />
        <Link color="white" linkText="Back to website" internalLink={{ slug: { current: "" } }} />
      </Container>
      <MainPaths />
    </Wrapper>
  );
};
