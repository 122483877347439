import React, { ReactNode } from "react";
import { colors } from "@util/constants";
import styled from "styled-components";

interface Props {
  selected?: boolean;
  bordered?: boolean;
  children: ReactNode;
  onClick?: () => void;
}

export default function Thumbnail({ children, ...rest }: Props) {
  return <Thumb {...rest} className="thumbnail">{children}</Thumb>;
}

const Thumb = styled.div<Partial<Props>>`
  border: ${props => {
    if (props.bordered) {
      return `1px solid ${colors.thumbGrey}`;
    } 
    if (props.selected) {
      return `1px solid ${colors.navy}`;
    }
    return "none";
  }};
  ${({ selected }) => selected && `background: ${colors.navy};`}
  padding: ${props => (props.bordered ? "0" : "5px")};

  cursor: pointer;
  transition: all 0.3s ease-in-out;

  img {
    width: 100%;
    height: auto;
  }

  :hover {
    scale: 1.1;
    border: 1px solid ${colors.thumbGrey};
  }
`;
