import React, { ChangeEvent, DragEvent, useState } from "react";
import styled from "styled-components";

import { Label } from "@shared/salesTool/components.styles";
import { Container } from "@util/standard";
import { colors } from "@util/constants";
import { useStore } from "@state/store";
import { Product } from "@state/types";
import useImageUpload from "@hooks/useImageUpload";
import ReactCrop from "react-image-crop";
import { Button } from "@global";
import { getAspectRatio } from "@util/canvasHelper";

interface Props {
  title?: string;
}

export default function UploadFile({ title }: Props) {
  const [isDragging, setIsDragging] = useState(false);
  const product = useStore(state => state.product);
  const aspectRatio = getAspectRatio(
    product?.advertSize?.width || 1,
    product?.advertSize?.height || 1,
  );

  const {
    handleSelectFile,
    previewCanvasRef,
    wholeImageCanvasRef,
    imageData,
    handleImageLoaded,
    crop,
    setCrop,
    setCompletedCrop,
    completedCrop,
  } = useImageUpload(aspectRatio);

  const hasCrop = Boolean(completedCrop?.width) && Boolean(completedCrop?.height);

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSelectFile(event);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleSelectFile(event);
      event.dataTransfer.clearData();
    }
  };

  return (
    <Wrapper
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragging={isDragging}
      className="upload-file"
    >
      {title && <h4>{title}</h4>}
      <div className="upload-box">
        <div className="upload">
          <span>Drag and drop an image or</span>
          <Label htmlFor="imageUpload" className="upload-btn">
            Upload your design
          </Label>
          <input
            type="file"
            id="imageUpload"
            accept="application/pdf, image/*"
            onChange={handleFileInputChange}
          />
          {/* canvas for cropped image */}
          <canvas ref={previewCanvasRef} />
          {/* canvas for whole image */}
          <canvas ref={wholeImageCanvasRef} />
        </div>

        <Container justifyContent="center" alignItems="center">
          {imageData ? (
            <Container
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              rowGap={5}
            >
              <Button disabled={!Boolean(hasCrop)}>Use cropped design</Button>
              <ReactCrop
                src={imageData as string}
                onImageLoaded={handleImageLoaded}
                crop={crop}
                onChange={c => setCrop(c)}
                onComplete={c => setCompletedCrop(c)}
                className="image-crop"
              />
              <p style={{ textAlign: "center", fontWeight: "bold" }}>
                Please drag over the image and crop your design to the preset aspect ratio
              </p>
            </Container>
          ) : (
            <p className="info-text">{infoText(product)}</p>
          )}
        </Container>
      </div>
    </Wrapper>
  );
}

const infoText = (product?: Product) => {
  const size = product?.advertSize
    ? `${product.advertSize.width}x${product.advertSize.height} px`
    : "";

  return `Size: ${size} (max 5MB) \n
  (Or of the same aspect ratio) \n
  File accepted: png, jpg, pdf \n
  Resolution: 150dpi`;
};

const Wrapper = styled.div<{ isDragging: boolean }>`
  margin-top: 100px;

  .image-crop {
    height: 50vh;

    img {
      height: 50vh;
      object-fit: contain;
    }
  }

  .upload-box {
    display: flex;
    padding: 30px;
    flex-direction: column;
    row-gap: 40px;
    align-items: center;
    border: 1px dashed ${colors.navy};
    border-radius: 10px;
    background-color: ${({ isDragging }) => (isDragging ? colors.lightBlue : "transparent")};
    color: ${colors.navy};

    .upload {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }
    .upload-btn {
      font-weight: bold;
    }
    .info-text {
      text-align: center;
    }

    canvas,
    #imageUpload {
      display: none;
    }
  }
`;
