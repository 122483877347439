import React, { useState } from "react";
import styled from "styled-components";

import { Button } from "@global";
import { useStore } from "@state/store";
import { colors } from "@util/constants";
import Thumbnail from "./thumbnail";
import UploadFile from "./uploadFile";
import useDashboard from "@hooks/useDashboard";

interface Props {}

const Template = ({}: Props) => {
  const [state, setState] = useState(0);
  const { handleSelectTemplate, handleSelectFile, onChangeInput, fileName, handleUpdateFormData } =
    useDashboard();
  const { templateAvailable, templateFieldset, template } = useStore();

  const handleState = (state: number) => () => {
    setState(state);
  };

  return (
    <Wrapper>
      <div className="button-opts">
        <span onClick={handleState(0)} className={state === 0 ? "active" : ""}>
          General info
        </span>

        <span onClick={handleState(1)} className={state === 1 ? "active" : ""}>
          Canvas
        </span>
      </div>

      {state === 0 && (
        <>
          <form>
            <span>Company Logo</span>
            <label htmlFor="file-upload" className="custom-file-upload">
              {fileName || "No file chosen"}
            </label>
            <input id="file-upload" type="file" onChange={handleSelectFile} />
            {templateFieldset?.map(field => {
              return (
                <div key={field.name} className="fieldset">
                  <label htmlFor={field.value}>{field.name}</label>
                  <input
                    type="text"
                    id={field.value}
                    // value={template?.formData?.[field.name]}
                    onChange={e => {
                      onChangeInput(field.name, e.target.value);
                    }}
                  />
                </div>
              );
            })}
          </form>
          <Button linkText="Update" width="100%" onClick={handleUpdateFormData} />
        </>
      )}
      {state === 1 && (
        <>
          <UploadFile />
          <Grid>
            {templateAvailable?.templates?.map((temp: any, index: number) => {
              return (
                <Thumbnail
                  key={index}
                  selected={temp.id === template?.id}
                  onClick={() => {
                    handleSelectTemplate(temp.id);
                  }}
                >
                  <img src={temp.thumbnail} />
                </Thumbnail>
              );
            })}
          </Grid>
        </>
      )}
    </Wrapper>
  );
};

export default Template;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  height: 100%;
  padding: 10px 0;
`;

const Wrapper = styled.div`
  width: 500px;
  margin: 20px;
  padding: 0 15px 15px 15px;
  border-radius: 10px;
  background: white;
  z-index: 5;
  display: flex;
  flex-direction: column;

  .button-opts {
    padding: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    span {
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      opacity: 0.3;
    }
    span.active {
      opacity: 1;
    }
  }

  .fieldset {
    display: grid;
  }

  input[type="file"] {
    display: none;
  }

  h2 {
    font-size: 20px;
    color: ${colors.navy};
  }

  p {
    color: ${colors.navy};
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    .custom-file-upload {
      margin: 10px 0 20px 0;
      height: 40px;
      color: ${colors.navy};
      border: 1px solid ${colors.navy};
      border-radius: 5px;
      padding-left: 10px;
    }

    .custom-file-upload {
      display: flex;
      align-items: center;
      padding-left: 5px;
      overflow: hidden;

      &::before {
        content: "Choose file";
        display: block;
        padding: 10px;
        margin-right: 10px;
        border-radius: 5px;
        background: #e6e6e6;
        color: ${colors.navy};
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  select {
    width: 100%;
    color: ${colors.navy};
    padding: 0 10px;
    height: 47px;
  }

  .template-title {
    position: fixed;
    background: white;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.3);
    width: 470px;
    left: 0;
    top: 48px;
    display: flex;
    padding: 15px;

    .button-wrapper {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .upload-file {
    margin-top: 0;

    .upload-box {
      padding: 30px 10px;
      row-gap: 20px;
    }

    .image-crop {
      height: 100%;
      img {
        height: inherit;
      }
    }
  }
`;
