import React from "react";
import styled from "styled-components";
import { Rnd } from "react-rnd";

import { DashboardLayout } from "@shared/dashboard";
import Template from "@shared/dashboard/template";
import { useStore } from "@state/store";
import { createQuillFontStyle } from "@util/helper";
import {
  AdBoard,
  AdvertContainer,
  ImageElement,
  TextElement,
} from "@shared/salesTool/components.styles";
import { getAspectRatio } from "@util/canvasHelper";

export default function Edit() {
  const template = useStore(state => state.template);
  const product = useStore(state => state.product);

  const width = template?.canvas?.width || product?.advertSize?.width || 1;
  const height = template?.canvas?.height || product?.advertSize?.height || 1;
  const adCopy = template?.adCopy;

  const aspectRatio = getAspectRatio(width, height);

  return (
    <DashboardLayout currentPath="dashboard">
      <Wrapper>
        <Template />
        <Advert>
          <AdBoard
            zoom={1}
            aspectRatio={aspectRatio}
            height={`${height}mm`}
            width={`${width}mm`}
            overrideBackgroundColour={adCopy?.backgroundColour}
            backgroundUrl={adCopy?.backgroundUrl}
          >
            {adCopy?.backgroundUrl && (
              <div className="background">
                <img src={adCopy?.backgroundUrl} />
              </div>
            )}
            {adCopy?.imageElements?.map((image, index) => {
              const { position, dimension, id } = image;
              return (
                <Rnd
                  disableDragging
                  key={id || index}
                  default={{
                    x: position?.x ?? 0,
                    y: position?.y ?? 0,
                    width: dimension?.width ?? "80px",
                    height: dimension?.height ?? "80px",
                  }}
                >
                  <ImageElement>
                    <img
                      src={image.value}
                      style={{
                        height: "100%",
                        width: "100%",
                        pointerEvents: "none",
                        userSelect: "none",
                        opacity: image.opacity ?? 1,
                      }}
                    />
                  </ImageElement>
                </Rnd>
              );
            })}

            {adCopy?.textElements?.map((textElement, index) => {
              const { value, dimension, position } = textElement;
              return (
                <Rnd
                  disableDragging
                  key={index}
                  position={{
                    x: position?.x ?? 0,
                    y: position?.y ?? 0,
                  }}
                  size={{
                    width: dimension?.width ?? "auto",
                    height: dimension?.height ?? "auto",
                  }}
                  default={{
                    x: position?.x ?? 0,
                    y: position?.y ?? 0,
                    width: dimension?.width ?? "auto",
                    height: dimension?.height ?? "auto",
                  }}
                >
                  <TextElement
                    width={dimension?.width}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                </Rnd>
              );
            })}
          </AdBoard>
        </Advert>
      </Wrapper>
    </DashboardLayout>
  );
}

const Wrapper = styled.div`
  display: flex;
`;

const Advert = styled(AdvertContainer)`
  position: sticky;
  top: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 48px);
  transition: all 0.2s ease-in-out;
  h1,
  h2,
  h3,
  h4,
  p {
    margin: 2px;
  }

  ${createQuillFontStyle()}
`;
